import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Root from './Root'
import ProductInfo from './ProductInfo'
import KDS from './KDS'  // Certifique-se de importar o componente KDS correto
import MesasAbertas from './MesasAbertas'

import { getProducts } from '../store/actions/products'

class Routes extends Component {
  componentDidMount() {
    if (localStorage.getItem('isLoggedIn') === 'true'){
      this.props.getProducts()
    }    
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact={true} path='/'> <Root /> </Route>
          <Route path='/produto'> <ProductInfo /> </Route>          
          <Route path="/mesas-abertas" exact component={MesasAbertas} />
          {/* Ajuste da rota com render para os parâmetros dinâmicos */}
          <Route path="/kds-itens/:tipo/:status" render={(props) => {
            const { tipo, status } = props.match.params;
            const titulo = `${tipo === 'bar' ? 'Bar' : 'Cozinha'} - ${status === 'forno' ? 'Forno' : 'Finalização'}`;
            return <KDS tipo={tipo} status={status} titulo={titulo} />;
          }} />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.productsReducer.isFetching,
    error: state.productsReducer.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProducts: () => dispatch(getProducts())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
