import React, { useState, useEffect } from 'react';
import api from '../services/api.js';
import FechamentoCaixa from './FechamentoCaixa';
import { useHistory } from 'react-router-dom';


const Header = ({ numeroMesaLink }) => {
  const [isOpenModalSemAcesso, setIsOpenModalSemAcesso] = useState(false);
  const [isOpenModalCaixaFechadoSucesso, setisOpenModalCaixaFechadoSucesso] = useState(false);
  const [isOpenModalGavetaAbertaSucesso, setisOpenModalGavetaAbertaSucesso] = useState(false);
  const [isOpenModalMesasAbertas, setisOpenModalMesasAbertas] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Novo estado para o menu
  const [isOpenModalSocios, setisOpenModalSocios] = useState(false);
  const [numeroMesaProntoParaEnvio, setNumeroMesaProntoParaEnvio] = useState(null); // Estado separado para envio
  const [loginId] = useState(() => localStorage.getItem('loginId') || '');

  const history = useHistory();

  const socios = [
    { id: 100, nome: 'Michel' },
    { id: 101, nome: 'Adriano' },
    { id: 102, nome: 'Sergio' },
    { id: 103, nome: 'Yanic' },
    { id: 104, nome: 'Dani' },
    { id: 105, nome: 'Leandro' },
    { id: 106, nome: 'Rodrigo' },
    { id: 107, nome: 'Documennta' },


  ];
  useEffect(() => {
    if (numeroMesaLink) {
      setNumeroMesaInput(numeroMesaLink.toString()); // Atualiza o valor no campo
      setNumeroMesaProntoParaEnvio(numeroMesaLink.toString()); // A
    }
  }, [numeroMesaLink]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Alterna o estado do menu
  };

  //777
  const handleMesasAbertasClick = () => {
    // setisOpenModalMesasAbertas(true);
    // setIsSubmitting(false);
    // setIsMenuOpen(false);
    history.push('/mesas-abertas');
  };

  const handleKDSBarFornoClick = () => {
    history.push('/kds-itens/bar/forno');
  };
  
  const handleKDSBarFinalizacaoClick = () => {
    history.push('/kds-itens/bar/finalizacao');
  };
  
  const handleKDSCozinhaFornoClick = () => {
    history.push('/kds-itens/cozinha/forno');
  };
  
  const handleKDSCozinhaFinalizacaoClick = () => {
    history.push('/kds-itens/cozinha/finalizacao');
  };
  
  const handleSociosClick = () => {
    setisOpenModalSocios(true);
    setIsMenuOpen(false);
  };

  const handleSocioClick = (numeroSocio) => {
    setNumeroMesaInput(numeroSocio.toString()); // Atualiza o valor no campo
    setisOpenModalSocios(false);
    setNumeroMesaProntoParaEnvio(numeroSocio.toString()); // Atualiza o estado que acionará o useEffect
  };

  useEffect(() => {
    if (numeroMesaProntoParaEnvio) {
      handleSubmit({ preventDefault: () => {} }); // Chama o submit após o valor estar pronto
    }
  }, [numeroMesaProntoParaEnvio]);

  const handleAbrirGavetaClick = async () => {
    const loginId = localStorage.getItem('loginId');
    if (loginId !== '7899' && loginId !== '5531' && loginId !== '4562' && loginId !== '1810') {
      setIsSubmitting(false);
      setIsOpenModalSemAcesso(true);
      //window.location.href = '/';
      return;
    }
    await api.post('imprimir-geral', {
      quem_enviou: localStorage.getItem('loginId'),
      acao: 'abrir_gaveta',
      parametro: 'x'
    });
    setisOpenModalGavetaAbertaSucesso(true);
  }

  const handleFecharCaixa = async () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    await api.post('imprimir-geral', {
      quem_enviou: localStorage.getItem('loginId'),
      acao: 'fechar_caixa',
      parametro: `${localStorage.getItem('loginId')}|${formattedDate}`
    });
    setisOpenModalCaixaFechadoSucesso(true);
    localStorage.removeItem('numeroMesa');
    localStorage.removeItem('idPedido');
    localStorage.removeItem('idArquivo');
    localStorage.removeItem('loginId');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('status');
    localStorage.removeItem('userCodigo');
    localStorage.removeItem('authToken');
  }

  const handleDesconectar = () => {
    localStorage.removeItem('numeroMesa');
    localStorage.removeItem('idPedido');
    localStorage.removeItem('idArquivo');
    localStorage.removeItem('loginId');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('status');
    localStorage.removeItem('userCodigo');
    localStorage.removeItem('authToken');
    setIsSubmitting(false);
    window.location.href = '/';
  }
  const [numeroMesaInput, setNumeroMesaInput] = useState(() => {
    const mesaFromStorage = localStorage.getItem('numeroMesa');
    return mesaFromStorage || '';
  });

  const [numeroMesa, setNumeroMesa] = useState(() => {
    const mesaFromStorage = localStorage.getItem('numeroMesa');
    return mesaFromStorage || '';
  });

  const [idPedido, setIdPedido] = useState(() => {
    const idPedidoFromStorage = localStorage.getItem('idPedido');
    return idPedidoFromStorage || null;
  });

  const [idArquivo, setIdArquivo] = useState(() => {
    const idArquivoFromStorage = localStorage.getItem('idArquivo');
    return idArquivoFromStorage || null;
  });


  const [status] = useState(() => {
    const statusFromStorage = localStorage.getItem('status');
    return statusFromStorage || null;
  });

  useEffect(() => {
    localStorage.setItem('idPedido', idPedido);
  }, [idPedido]);

  useEffect(() => {    
    localStorage.setItem('numeroMesa', numeroMesa);
    localStorage.setItem('idPedido', idPedido);
    localStorage.setItem('idArquivo', idArquivo);

    const element = document.querySelector('.product-list');
    if (element) {
      if (numeroMesa.trim() !== '') {
        if (status !== "Fechada"){
           element.classList.remove('block-a');
           document.querySelector('.accordion').style.display = 'block';
        }else{
          
           document.querySelector('.accordion').style.display = 'block';
        }
      } else {
        element.classList.add('block-a');
        document.querySelector('.accordion').style.display = 'none';
      }
    }
  }, [numeroMesa,idPedido]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleModalButtonClickFechadoComSucesso = () => {        
    setisOpenModalCaixaFechadoSucesso(false);  
    setIsSubmitting(false);  
    window.location.href = '/';
  };

  const handleModalButtonClickGavetaAbertaSucesso = () => {        
    setisOpenModalGavetaAbertaSucesso(false);  
    setIsSubmitting(false);  
    window.location.href = '/';
  };

  const handleModalButtonClickMesasAbertas = () => {        
    setisOpenModalMesasAbertas(false);  
    setIsSubmitting(false);  
    window.location.href = '/';
  };


  const handleModalButtonClickSocios = () => {        
    setisOpenModalSocios(false);  
    setIsSubmitting(false);  
    window.location.href = '/';
  };
  

  const handleSubmit = async (event) => {    
    event.preventDefault();

    /* eslint-disable eqeqeq */
    if (numeroMesaInput == ''){
      localStorage.removeItem('numeroMesa');
      localStorage.removeItem('idPedido');
      localStorage.removeItem('idArquivo');            
      localStorage.removeItem('status');
      setIsSubmitting(false);      
      window.location.href = '/';
    }
    /* eslint-enable eqeqeq */


    if (!isSubmitting && numeroMesaInput.trim() !== '') {
      setIsSubmitting(true);
        try {
          if (numeroMesaInput === '555') {
            handleMesasAbertasClick();            
          }else if (numeroMesaInput === '777') {
            handleAbrirGavetaClick();
          }else if (numeroMesaInput === '888') {
            handleFecharCaixa();
          }else if (numeroMesaInput === '999') {
            handleDesconectar();
          }else{ 
            const response = await api.post('mesa-pedido', {
              numero_mesa: numeroMesaInput,
              quem_abriu: localStorage.getItem('loginId'),
              n_pessoas_mesa:'1',
              imprimir: false
            });          
            
            setIdPedido(response.data.id);
            setIdArquivo(response.data.id_arquivo);
            localStorage.setItem('status', response.data.status);
            setNumeroMesa(numeroMesaInput);            
            setIsSubmitting(false);
            window.location.href = '/';   
          }   
      } catch (error) {        
        setIsSubmitting(false);
        console.error('Erro ao verificar a mesa:', error);
        // Aqui você pode lidar com o erro de verificação da mesa
      }
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length === 0 || (newValue.length <= 3 && parseInt(newValue) >= 1)) {
      setNumeroMesaInput(newValue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };  

  return (
    <header>
      <div className='mesa'>
        <form>
          <span>Mesa</span>
          <input 
            type="text" 
            inputMode="numeric"
            name="numero" 
            id="numero" 
            value={numeroMesaInput} 
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            // onBlur={handleBlur}
            onClick={(e) => e.target.select()}
          />
          <button type="submit" onClick={handleSubmit}>Ok</button>
        </form>
      </div>
      <div className={status === "Fechada" || status === null ? 'numeromesa-fechada' : 'numeromesa'}>
        <p>{numeroMesa || ''}</p>

        {/* Menu de três barrinhas no canto direito superior */}
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        {/* Dropdown do menu */}
        {isMenuOpen && (
          <div className="menu-dropdown">
            <ul>
              <li style={{ paddingTop: '5px' }} onClick={handleMesasAbertasClick}>- Mesas abertas</li>                                          
              <li onClick={handleSociosClick}>- Socios</li>
              {(loginId === '7899' || loginId === '5531' || loginId === '4562' || loginId === '1810') && (
                <li onClick={handleAbrirGavetaClick}>- Abrir gaveta</li>                
              )}              
              {(loginId === '7899x' || loginId === '5531' || loginId === '4562x' || loginId === '1810x' || loginId === '8888' || loginId === '8887') && (
                <li onClick={handleKDSBarFornoClick}>- KDS Bar (Preparo)</li>
              )}                            
              {(loginId === '7899x' || loginId === '5531' || loginId === '4562x' || loginId === '1810x' || loginId === '8888' || loginId === '8887') && (
                <li onClick={handleKDSBarFinalizacaoClick}>- KDS Bar (Finalização)</li>
              )}
              {(loginId === '7899x' || loginId === '5531' || loginId === '4562x' || loginId === '1810x' || loginId === '8888' || loginId === '8887') && (
                <li onClick={handleKDSCozinhaFornoClick}>- KDS Cozinha (Forno)</li>
              )}                            
              {(loginId === '7899x' || loginId === '5531' || loginId === '4562x' || loginId === '1810x' || loginId === '8888' || loginId === '8887') && (
                <li onClick={handleKDSCozinhaFinalizacaoClick}>- KDS Cozinha (Finalização)</li>
              )}                                                        
              <li style={{ paddingTop: '1px' }}><hr></hr></li>
              <li style={{ paddingTop: '1px' }} onClick={handleFecharCaixa}>- Fechar Caixa</li>
              <li style={{ paddingTop: '1px' }}><hr></hr></li>
              <li style={{ paddingTop: '1px',paddingBottom: '10px' }} onClick={handleDesconectar}>- Desconectar</li>
              
            </ul>
          </div>
        )}
      </div>

      {isOpenModalSemAcesso && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Sem acesso para essa função</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={() => setIsOpenModalSemAcesso(false)}>Ok</button>
                        </div>
                    </div>
                </div>
        )}
        {isOpenModalCaixaFechadoSucesso && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Caixa fechado com sucesso!</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={handleModalButtonClickFechadoComSucesso}>Ok</button>
                        </div>
                    </div>
                </div>
        )}
        {isOpenModalGavetaAbertaSucesso && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Gaveta aberta com sucesso!</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={handleModalButtonClickGavetaAbertaSucesso}>Ok</button>
                        </div>
                    </div>
                </div>
        )}
        {isOpenModalMesasAbertas && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Mesas abertas</h3>
                        <div className="scrollable-content">
                          <FechamentoCaixa />
                        </div>
                        <div className='botoes'>
                            <button className='confirmar' onClick={handleModalButtonClickMesasAbertas}>Fechar</button>
                        </div>
                    </div>
                </div>
        )}        
       {isOpenModalSocios && (
        <div className='content-modal'>
        <div className='modal-content'>
          <h3 className="modal-title">Sócios</h3>
          <div className="scrollable-content">
            <ul className="socio-list">
              {socios.map((socio) => (
                <li key={socio.id}>
                  <button className="socio-button" onClick={() => handleSocioClick(socio.id)}>
                    {socio.id} - {socio.nome}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className='botoes'>
            <button className='confirmar' onClick={() => setisOpenModalSocios(false)}>Fechar</button>
          </div>
        </div>
      </div>
      
      )}

    </header>
  );
};

export default Header;
