import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';

const KDS = ({ tipo, status, titulo }) => {
  const [mesasConsumo, setMesasConsumo] = useState([]);
  const [clickCount, setClickCount] = useState({});
  const [previousHash, setPreviousHash] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const intervalRef = useRef(null);

  const generateHash = (data) => JSON.stringify(data);

  const TEMPOS_MUDANCA_COR = {
    "Bar - Forno": [3, 4, 5], // em minutos
    "Bar - Finalização": [3, 4, 5], 
    "Cozinha - Forno": [4, 6, 8], 
    "Cozinha - Finalização": [10, 15, 20] 
  };

  const fetchMesasConsumo = async () => {
    try {
      const response = await api.get(`/kds-itens/${tipo}/${status}`);
      const currentHash = generateHash(response.data);

      if (currentHash !== previousHash) {
        setMesasConsumo(response.data);
        setPreviousHash(currentHash);
      }
    } catch (error) {
      console.error('Erro ao buscar mesas com consumo:', error);
    }
  };

  const startInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(fetchMesasConsumo, 5000);
  };

  useEffect(() => {
    fetchMesasConsumo();
    startInterval();

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(timerId);
  }, []);

  const handleItemClick = async (itemId, quantidade, clicked) => {
    clearInterval(intervalRef.current);

      const campoClick = status === 'forno' ? 'datahora_forno_click' : 'datahora_finalizacao_click';

    if (clicked) {
      setClickCount((prevCount) => {
        const currentCount = prevCount[itemId] || 0;
        const newCount = currentCount > 0 ? quantidade - 1 : 0;
        return { ...prevCount, [itemId]: newCount };
      });
      
      api.put(`/mesa-consumo/update-click/${itemId}/${campoClick}/unset`)
        .then(() => {
          setMesasConsumo((prevState) =>
            prevState.map((mesa) => ({
              ...mesa,
              mesa_consumo: mesa.mesa_consumo.map((item) =>
                item.id === itemId
                  ? { ...item, clicked: false }
                  : item
              ),
            }))
          );
        })
        .catch((error) => console.error('Erro ao restaurar o clique:', error));

      setTimeout(() => {
        startInterval();
      }, 3000);

      return;
    }

    setClickCount((prevCount) => {
      const newCount = (prevCount[itemId] || 0) + 1;

      if (newCount >= quantidade) {        
        api.put(`/mesa-consumo/update-click/${itemId}/${campoClick}/set`)
          .then(() => {
            setMesasConsumo((prevState) =>
              prevState.map((mesa) => ({
                ...mesa,
                mesa_consumo: mesa.mesa_consumo.map((item) =>
                  item.id === itemId
                    ? { ...item, clicked: true }
                    : item
                ),
              }))
            );
          })
          .catch((error) => console.error('Erro ao atualizar o clique:', error));
      }

      return { ...prevCount, [itemId]: newCount };
    });

    setTimeout(() => {
      startInterval();
    }, 3000);
  };

  // const getElapsedTimeInMinutes = (createdAt) => {
  //   const timeElapsed = (currentTime - new Date(createdAt).getTime()) / 1000;
  //   const minutes = Math.floor(timeElapsed / 60);
  //   const seconds = Math.floor(timeElapsed % 60);
  //   return `${minutes}m ${seconds}s`;
  // };

  const getElapsedTimeInMinutes = (createdAt, datahoraImpressao) => {  
    const currentTime = new Date().getTime(); // Captura o tempo atual
    const createdAtTime = new Date(createdAt).getTime(); // Converte createdAt para timestamp
    const datahoraImpressaoTime = new Date(datahoraImpressao).getTime(); // Converte datahoraImpressao para timestamp
  
    // Se não houver atraso, apenas mostrar o tempo decorrido
    if (datahoraImpressaoTime === createdAtTime) {
      const timeElapsed = (currentTime - createdAtTime) / 1000; // Tempo decorrido em segundos
      const minutes = Math.floor(timeElapsed / 60);
      const seconds = Math.floor(timeElapsed % 60);
      
      return (
        <>
          {minutes}m {seconds}s
        </>
      );
    }
  
    // Cálculo do atraso (tempo entre `createdAt` e `datahoraImpressao`)
    const delayTime = (datahoraImpressaoTime - createdAtTime) / 1000; // Atraso em segundos
    const delayMinutes = Math.floor(delayTime / 60); // Atraso em minutos
    const delaySeconds = Math.floor(delayTime % 60); // Atraso em segundos
  
    // Se ainda estamos no tempo de atraso (antes do início do preparo)
    if (currentTime < datahoraImpressaoTime) {
      const timeUntilStart = (datahoraImpressaoTime - currentTime) / 1000;
      const minutesUntilStart = Math.floor(timeUntilStart / 60);
      const secondsUntilStart = Math.floor(timeUntilStart % 60);
  
      return (
        <>
          {minutesUntilStart}m {secondsUntilStart}s
          {" "} |{" "}
          <svg
            className="relogio-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="white"
          >
            <path
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
              fill="#fff"
            />
            <path d="M12 6V12L16.5 15" stroke="#fff" strokeWidth="2" />
          </svg>{" "}
          {/* / {delayMinutes}m {delaySeconds}s (Atraso)           */}
          / {delayMinutes}m
        </>
      );
    }
  
    // Quando o tempo de atraso terminar, calcular o tempo decorrido subtraindo o atraso
    const timeElapsedAfterDelay = (currentTime - datahoraImpressaoTime) / 1000; // Tempo decorrido após o atraso
    const minutesElapsed = Math.floor(timeElapsedAfterDelay / 60);
    const secondsElapsed = Math.floor(timeElapsedAfterDelay % 60);
  
    return (
      <>
        {minutesElapsed}m {secondsElapsed}s
        {" "} |{" "}
        <svg
          className="relogio-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path
            d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
            fill="#ccc"
          />
          <path d="M12 6V12L16.5 15" stroke="#ccc" strokeWidth="2" />
        </svg>{" "}
        {/* / {delayMinutes}m {delaySeconds}s (Atraso) */}
        / {delayMinutes}m
      </>
    );
  };
  

  const getBackgroundColor = (datahora_imprimir, clicked, category) => {    
    if (clicked) return 'pedido-item-clicked';
  
    const timeElapsed = (currentTime - new Date(datahora_imprimir).getTime()) / 1000;
    
    if (timeElapsed < 0){ 
      return 'pedido-item-5';
    }

    if (category === 'Bar - Forno') {
      if (timeElapsed < TEMPOS_MUDANCA_COR['Bar - Forno'][0] * 60) return 'pedido-item-1'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Bar - Forno'][1] * 60) return 'pedido-item-2'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Bar - Forno'][2] * 60) return 'pedido-item-3'; 
    }
    
    if (category === 'Bar - Finalização') {
      if (timeElapsed < TEMPOS_MUDANCA_COR['Bar - Finalização'][0] * 60) return 'pedido-item-1'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Bar - Finalização'][1] * 60) return 'pedido-item-2'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Bar - Finalização'][2] * 60) return 'pedido-item-2'; 
    }
    
    if (category === 'Cozinha - Forno') {
      if (timeElapsed < TEMPOS_MUDANCA_COR['Cozinha - Forno'][0] * 60) return 'pedido-item-1'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Cozinha - Forno'][1] * 60) return 'pedido-item-2'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Cozinha - Forno'][2] * 60) return 'pedido-item-3'; 
    }
    
    if (category === 'Cozinha - Finalização') {
      if (timeElapsed < TEMPOS_MUDANCA_COR['Cozinha - Finalização'][0] * 60) return 'pedido-item-1'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Cozinha - Finalização'][1] * 60) return 'pedido-item-2'; 
      if (timeElapsed < TEMPOS_MUDANCA_COR['Cozinha - Finalização'][2] * 60) return 'pedido-item-3'; 
    }
        
    return 'pedido-item-4';        
  };

  const formatTextoImprimir = (texto) => {
    let formattedText = texto.replace(/\n/g, '<br />');
    if (formattedText.includes('|')) {
      const [mainText, observation] = formattedText.split('|');
      formattedText = `${mainText}<br /><b>${observation}</b>`;
    }
    return formattedText;
  };

  // Reorganizando blocos por altura, simulando o efeito "Tetris"
  const getSortedBlocks = (blocks) => {
    return blocks.sort((a, b) => a.mesa_consumo.length - b.mesa_consumo.length);
  };

  return (
    <div className="kds-bar">
      <Link to={`/`} className="close-bar">        
      <span className="titulo-kds">
          {titulo === 'Bar - Forno' ? 'Bar Preparo' : titulo} 
          {` (${TEMPOS_MUDANCA_COR[titulo].join('m, ')}m)`}
      </span>
        <span className="close-icon">&#10005;</span>
      </Link>
      <div className="kds">
        {mesasConsumo.length === 0 ? (
          <p>Aguardando Itens...</p>
        ) : (
          <div className="mesas-grid">
            {getSortedBlocks(mesasConsumo).map((mesa) => {
              let previousItemTime = null;
              let blocks = [[]];

              mesa.mesa_consumo.forEach((item) => {
                const currentItemTime = new Date(item.created_at).getTime();                

                if (previousItemTime && Math.abs(currentItemTime - previousItemTime) > 2 * 60 * 1000) {
                  blocks.push([]);
                }

                blocks[blocks.length - 1].push(item);
                previousItemTime = currentItemTime;
              });

              const totalBlocks = blocks.length;

              return blocks.map((block, index) => (
                <div className="mesa-group" key={`${mesa.numero_mesa}-${index}`}>
                  <center>
                    Mesa {mesa.numero_mesa} {totalBlocks > 1 && (
                      <span style={{ fontSize: '0.8em' }}> - Grupo ({index + 1}/{totalBlocks})</span>
                    )}
                  </center>
                  <div className="mesa-itens">
                    {block.map((item) => (
                      <div
                        key={item.id}
                        className={getBackgroundColor(item.datahora_imprimir, item.clicked, titulo)}
                        onClick={() => handleItemClick(item.id, item.quantidade, item.clicked)}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <p>{getElapsedTimeInMinutes(item.created_at,item.datahora_imprimir)}</p>
                          {item.quantidade > 1 && (
                            <p><b>{(clickCount[item.id] || 0)}/{item.quantidade}</b></p>
                          )}
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formatTextoImprimir(item.texto_imprimir) }}></p>
                      </div>
                    ))}
                  </div>
                </div>
              ));
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default KDS;
